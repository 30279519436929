<template>
  <div class="mypage-layout">
    <TabLayout :tabs="tabs" :tabIndex="tabIndex" :onTab="onTab" />
    <PaginationLayout
      :lastPage="lastPage"
      :onPage="onPage"
      :hasData="sortedData.length > 0"
    >
      <div v-for="(item, index) in sortedData" :key="index">
        <MyPlaceItem
          v-if="item.type === 1"
          :data="item.data"
          :onDelete="onDeletePlaceItem"
        />

        <PlaceOfferItem
          v-if="item.type === 2"
          :data="item.data"
          :onDetail="onDetailPlace"
        />

        <InteriorOfferItem 
          v-if="item.type === 3" 
          :data="item.data" 
        />

        <InteriorJoinOfferItem
          v-if="item.type === 4"
          :data="item.data"
        />
      </div>
    </PaginationLayout>

    <PlaceHistory :id="placeOfferId" :onRemove="onRemovePlace" />
  </div>
</template>

<script>
import PaginationLayout from "@/components/PaginationLayout.vue";
import InteriorOfferItem from "@/components/InteriorOfferItem.vue";
import MyPlaceItem from "@/components/MyPlaceItem.vue";
import PlaceOfferItem from "@/components/PlaceOfferItem.vue";
import InteriorJoinOfferItem from "@/components/InteriorJoinOfferItem.vue";
import TabLayout from "@/components/TabLayout.vue";
import PlaceHistory from "@/components/PlaceHistory.vue";
import services from "@/services";
import { mapState } from "vuex";
import dayjs from "dayjs";

export default {
  name: "MyPageLayout",
  components: {
    PaginationLayout,
    InteriorOfferItem,
    MyPlaceItem,
    PlaceOfferItem,
    InteriorJoinOfferItem,
    PlaceHistory,
    TabLayout,
  },
  data() {
    return {
      tabs: ["공간", "비교견적"],
      tabIndex: 0,
      lastPage: 1,
      currentPage: 1,
      placeOfferId: -1,
      data1: [],
      data2: [],
      data3: [],
      data4: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onTab(value) {
      this.tabIndex = value;
      this.currentPage = 1;
      this.lastPage = 1;
      this.data1 = [];
      this.data2 = [];
      this.data3 = [];
      this.data4 = [];
      this.getData();
    },
    onPage: function(value) {
      this.currentPage = value;
      this.getData();
    },
    async onDeletePlaceItem(placeId) {
      const success = await services.placeRemove(placeId);
      if (success) {
        this.$toast.open("공간삭제 완료");
        this.getData();
      } else {
        this.$toast.open({ message: "공간삭제 실패", type: "error" });
      }
    },
    async getPlaceList() {
      const { lastPage, data } = await services.placeList({
        paginate: 5,
        page: this.currentPage,
        owner_id: this.userId,
      });

      this.lastPage = lastPage;
      this.data1 = data.filter((d) => d.confirmCnt === 0);
    },
    async getPlaceOfferList() {
      const { lastPage, data } = await services.placeJoinOfferList({
        paginate: 5,
        page: this.currentPage,
        user_id: this.userId,
      });

      this.lastPage = lastPage;
      this.data2 = data.filter((d) => d.confirmCnt === 0);
    },
    async getInteriorOfferList() {
      const { lastPage, data } = await services.interiorOfferList({
        paginate: 5,
        page: this.currentPage,
        user_id: this.userId,
      });

      this.lastPage = lastPage;
      this.data3 = data.filter((d) => d.confirmCnt === 0);
    },
    async getInteriorJoinOfferList() {
      const { lastPage, data } = await services.interiorJoinOfferList({
        paginate: 5,
        page: this.currentPage,
        owner_id: this.userId,
      });

      this.lastPage = lastPage;
      this.data4 = data.filter((d) => d.confirmCnt === 0);
    },
    async getData() {
      if (this.tabIndex === 0) {
        await Promise.all([this.getPlaceList(), this.getPlaceOfferList()]);
      } else if (this.tabIndex === 1) {
        await Promise.all([
          this.getInteriorOfferList(),
          this.getInteriorJoinOfferList(),
        ]);
      }
    },
    onDetailPlace(item) {
      this.placeOfferId = item.id;
      this.$modal.show("place-modal");
    },
    async onRemovePlace(id) {
      const success = await services.placeJoinOfferRemove(id);
      if (success) {
        this.$modal.hide("place-modal");
        this.$toast.open({ message: "삭제완료" });
        this.getData();
      } else {
        this.$toast.open({ message: "삭제실패", type: "error" });
      }
    },
  },
  computed: {
    sortedData() {
      if (this.tabIndex === 0) {
        const result = this.data1
          .map((d) => ({ data: d, type: 1 }))
          .concat(this.data2.map((d) => ({ data: d, type: 2 })))
          .sort((a, b) => {
            return dayjs(a.data.updatedAt).isAfter(dayjs(b.data.updatedAt));
          });

        return result;
      }

      const result = this.data3
        .map((d) => ({ data: d, type: 3 }))
        .concat(this.data4.map((d) => ({ data: d, type: 4 })))
        .sort((a, b) => {
          return dayjs(a.data.updatedAt).isAfter(dayjs(b.data.updatedAt));
        });

      return result;
    },
    ...mapState("user", ["userId"]),
  },
};
</script>

<style scoped lang="scss"></style>
