<template>
  <div class="flex flex-row items-center justify-between place-my-item-wrapper">
    <div class="place-my-item flex flex-1 h-full">
      <div
        @click="goToDetail"
        class="image cursor-pointer"
        :style="{
          backgroundImage: image ? `url(${image})` : '',
        }"
      />

      <div class="flex flex-col justify-center cursor-pointer">
        <p @click="goToDetail" class="name mb-4">{{ data.title }}</p>
        <p @click="goToDetail" class="date">
          {{ date }}<span v-if="!!date" class="ml-2">(공간등록일)</span>
        </p>
      </div>
    </div>

    <div class="flex flex-col button-group">
      <button v-if="isConfirm" class="flex justify-center items-center">
        임대신청
      </button>
      <button
        @click="() => onDelete(data.id)"
        v-if="isConfirm"
        class="flex justify-center items-center delete"
      >
        삭제
      </button>

      <button
        @click="goToOffer"
        v-if="!isConfirm"
        class="flex justify-center items-center active"
      >
        경쟁입찰({{ offers.length }})
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import utils from "@/utils";

export default {
  name: "MyPlaceItem",
  props: {
    data: Object,
    onDelete: Function,
  },
  methods: {
    goToDetail() {
      this.$router.push({ name: "PlaceDetail", params: { id: this.data.id } });
    },
    goToOffer() {
      this.$router.push({
        name: "PlaceOfferJoinList",
        params: { id: this.data.id },
      });
    },
  },
  computed: {
    offers() {
      const { hasPlaceJoinOffer } = this.data;
      if (hasPlaceJoinOffer) {
        return hasPlaceJoinOffer;
      }
      return [];
    },
    isConfirm() {
      return this.offers.length === 0;
    },
    image() {
      const images = utils.getPlaceImages(this.data);
      console.log(this.data);
      if (images.length > 0) {
        return images[0];
      }
      return "";
    },
    date() {
      const { createdAt } = this.data;
      return dayjs(createdAt).format("YYYY-MM-DD");
    },
    ...mapState("user", ["userId"]),
  },
};
</script>

<style scoped lang="scss">
.place-my-item-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eeeeee;

  .image {
    width: 192px;
    height: 137px;
    background-color: #ddd;
    border-radius: 10px;
    margin-right: 23px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .name {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.8px;
    text-align: left;
    color: #222;
  }

  .date {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.8px;
    text-align: left;
    color: #222;

    span {
      color: #737373;
    }
  }

  .button-group {
    button {
      width: 135px;
      height: 46px;
      border-radius: 10px;
      background-color: #bfbfbf;

      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.16px;
      text-align: left;
      color: #fff;

      &:not(:last-child) {
        margin-bottom: 9px;
      }

      &.active {
        background-color: #293bf0;
      }

      &.delete {
        background-color: #e05050;
      }
    }
  }
}

@media (max-width: 639px) {
  .place-my-item-wrapper {
    width: 100%;
    flex-direction: column;
    .place-my-item {
      width: 100%;
      flex-direction: column;
    }
    .image {
      width: 150px;
      height: 100px;
      margin: 0 auto 15px;
    }
    .name {
      font-size: 13px;
      margin-bottom: 5px;
    }
    .date {
      font-size: 12px;
      margin-bottom: 15px;
    }
    .button-group {
      width: 100%;
      max-width: 320px;
      button {
        width: 100%;
        font-size: 13px;
        height: 35px;
      }
    }
  }
}
</style>
