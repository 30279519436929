<template>
  <div class="mypage-wrapper w-full h-full mb-20">
    <Jumbotron1 />
    <PageHeader title="마이페이지" v-bind:hasMenu="false" />

    <div class="w-full flex justify-center items-center">
      <div class="container flex flex-col">
        <div class="mypage-header flex flex-row justify-between">
          <div class="flex flex-row items-center">
            <div class="user-info flex flex-col justify-start items-start">
              <div class="user-badge" v-if="isInterior">인테리어 전문가</div>
              <div class="user-badge" v-else>일반회원</div>

              <p>
                <b>{{ getUser.name }}</b
                >({{ getUser.nickname }}) <span>님</span>
              </p>
              <div class="user-menu flex flex-row items-center">
                <div @click="goToUpdate">회원정보수정</div>
                <div class="mx-2">|</div>
                <div @click="logout">로그아웃</div>
              </div>
            </div>
          </div>

          <div class="user-menu-list flex flex-row">
            <div
              @click="goToHistory"
              class="user-menu-item flex flex-col items-center"
            >
              <h4 class="active">{{ tradeCnt }}</h4>
              <p>거래내역</p>
            </div>

            <div
              @click="goToFavorite"
              class="user-menu-item flex flex-col items-center"
            >
              <h4>{{ favoriteCnt }}</h4>
              <p>찜내역</p>
            </div>

            <div
              @click="goToReview"
              class="user-menu-item flex flex-col items-center"
            >
              <h4>{{ reviewCnt }}</h4>
              <p>후기내역</p>
            </div>

            <div
              @click="goToNotice"
              class="user-menu-item flex flex-col items-center"
            >
              <img src="@/assets/contact.png" />
              <p>고객센터</p>
            </div>
          </div>
        </div>

        <MyPageLayout />
      </div>
    </div>
  </div>
</template>

<script>
import Jumbotron1 from "@/components/Jumbotron1.vue";
import PageHeader from "@/components/PageHeader.vue";
import MyPageLayout from "@/components/MyPageLayout.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import services from "@/services";

export default {
  name: "MyPage",
  components: {
    Jumbotron1,
    PageHeader,
    MyPageLayout,
  },
  data() {
    return {
      tradeCnt: 0,
      favoriteCnt: 0,
      reviewCnt: 0,
    };
  },
  mounted() {
    this.me();
    this.getTrade();
    this.getFavorite();
    this.getReview();
  },
  computed: {
    isInterior() {
      return this.getUser.isInteriorAppConfirm;
    },
    ...mapState("place", ["subscribes"]),
    ...mapState("user", ["userId", "userType"]),
    ...mapGetters("user", ["getUser"]),
  },
  methods: {
    async getTrade() {
      const [place, placeMe, interior, interiorMe] = await Promise.all([
        services.placeJoinOfferList({ userId: this.userId, isConfirm: true }),
        services.placeJoinOfferList({
          placeOwnerId: this.userId,
          isConfirm: true,
        }),
        services.interiorJoinOfferList({
          ownerId: this.userId,
          isConfirm: true,
        }),
        services.interiorJoinOfferList({
          interiorOfferUserId: this.userId,
          isConfirm: true,
        }),
      ]);
      this.tradeCnt =
        place.length + placeMe.length + interior.length + interiorMe.length;
    },
    async getFavorite() {
      const [
        { total: placeTotal },
        { total: interiorTotal },
      ] = await Promise.all([
        services.placeList({ paginate: 5, subscribeUserId: this.userId }),
        services.interiorOfficeList({
          paginate: 5,
          subscribeUserId: this.userId,
        }),
      ]);
      this.favoriteCnt = placeTotal + interiorTotal;
    },
    async getReview() {
      const [place, interior] = await Promise.all([
        services.reviewList({ userId: this.userId }),
        services.reviewList({ receiveUserId: this.userId }),
      ]);
      this.reviewCnt = place.length + interior.length;
    },
    goToFavorite() {
      this.$router.push({ name: "Favorite" });
    },
    goToHistory() {
      this.$router.push({ name: "History" });
    },
    goToNotice() {
      this.$router.push({ name: "NoticeList" });
    },
    goToReview() {
      this.$router.push({ name: "MyPageReview" });
    },
    goToUpdate() {
      this.$router.push({ name: "UserUpdate" });
    },
    ...mapActions("user", ["me", "logout"]),
    ...mapActions("interior", ["meOffice"]),
  },
};
</script>

<style lang="scss" scoped>
.mypage-wrapper {
  margin-top: 0px;

  .mypage-header {
    margin-top: 32px;
    padding-bottom: 22px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 44px;
  }

  .user-image {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    background-color: #ccc;
    margin-right: 28px;
  }

  .user-info {
    .user-badge {
      height: 17px;
      border-radius: 8.5px;
      background-color: #283aef;
      padding-left: 8px;
      padding-right: 8px;

      font-size: 9.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.38px;
      text-align: left;
      color: #fff;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.64px;
      text-align: left;
      color: #343434;

      b {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.72px;
        color: #333;
      }

      span {
        font-size: 18px;
        font-weight: normal;
        letter-spacing: -0.72px;
        color: #333;
      }
    }

    .user-menu {
      margin-top: 9px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.56px;
      text-align: left;
      color: #333;

      .space {
        margin-right: 7px;
        margin-left: 7px;
        color: #9f9f9f;
        font-size: 10px;
      }

      a,
      div {
        cursor: pointer;
      }
    }
  }

  .user-menu-item {
    width: 108px;
    cursor: pointer;

    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.56px;
      text-align: left;
      color: #606060;
    }

    h4 {
      margin-bottom: 3px;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -1.2px;
      text-align: center;
      color: #606060;

      &.active {
        color: #5350c1;
      }
    }
  }
}

@media (max-width: 639px) {
  .mypage-wrapper {
    .container {
      overflow: hidden;
    }
    .mypage-header {
      flex-direction: column;
      margin-bottom: 20px;
    }
    .user-info {
      p {
        font-size: 13px;
        b {
          font-size: 13px;
        }
        span {
          font-size: 13px;
        }
      }
      .user-menu {
        font-size: 12px;
      }
    }
    .user-menu-list {
      margin-top: 15px;
    }
    .user-menu-item {
      h4 {
        font-size: 20px;
      }
      img {
        height: 30px;
        object-fit: contain;
        margin-bottom: 3px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
</style>
