<template>
  <div class="interior-join-offer-wrapper flex flex-row items-center">
    <div class="image" :style="{ backgroundImage: `url(${image})` }" />

    <div class="interior-join-offer-item h-full flex-1 flex flex-col justify-around">
      <h3>{{ title }}</h3>

      <div>
        <p class="place">{{ data.address }}</p>
        <p class="date">{{ date }} <span>(견적요청일)</span></p>
      </div>
    </div>

    <div v-if="canOffer" class="button-group">
      <button
        @click="goToOffer"
        :class="[
          'flex',
          'justify-center',
          'items-center',
          { disable: isDisable },
        ]"
        :disabled="isDisable"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import utils from "@/utils";
import dayjs from "dayjs";

export default {
  name: "InteriorOfferItem",
  props: {
    data: Object,
    canOffer: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goToOffer() {
      this.$router.push({
        name: "InteriorOfferJoinList",
        params: { id: this.data.id },
      });
    },
  },
  computed: {
    image() {
      const images = utils.getInteriorOfferImages(this.data);
      if (images.length > 0) {
        return images[0];
      }
      return "";
    },
    title() {
      const { hasPlaceClassification } = this.data;
      if (hasPlaceClassification) {
        return `${hasPlaceClassification.title} 인테리어`;
      }
      return "인테리어";
    },
    date() {
      return dayjs(this.data.createdAt).format("YYYY-MM-DD");
    },
    isDisable() {
      const { hasInteriorJoinOffers } = this.data;
      return hasInteriorJoinOffers.length === 0;
    },
    buttonText() {
      if (this.isDisable) {
        return "견적요청";
      }
      const { hasInteriorJoinOffers } = this.data;
      return `입찰참여(${hasInteriorJoinOffers.length})`;
    },
  },
};
</script>

<style scoped lang="scss">
.interior-join-offer-wrapper {
  height: 185px;
  border-bottom: 1px solid #eeeeee;

  .image {
    width: 190px;
    height: 140px;
    border-radius: 10px;
    margin-right: 30px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.8px;
    text-align: left;
    color: #222;
    margin-top: 20px;
  }

  .place {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.56px;
    text-align: left;
    color: #737373;
  }

  .date {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    color: #616161;
    margin-bottom: 20px;

    span {
      font-weight: normal;
    }
  }

  .button-group {
    button {
      width: 135px;
      height: 46px;
      border-radius: 10px;
      background-color: #283aef;

      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.16px;
      text-align: left;
      color: #fff;

      &.disable {
        background-color: #bfbfbf;
      }
    }
  }
}

@media (max-width: 639px) {
  .interior-join-offer-wrapper {
    height: auto;
    width: 100%;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    .interior-join-offer-item {
      width: 100%;
    }
    .image {
      width: 150px;
      height: 100px;
      margin: 0 auto 15px;
    }
    h3 {
      margin-top: 0;
      font-size: 13px;
    }
    .place {
      font-size: 12px;
    }
    .date {
      font-size: 12px;
      margin-bottom: 15px;
    }
    .button-group {
      width: 100%;
      max-width: 320px;
      button {
        width: 100%;
        font-size: 13px;
        height: 35px;
      }
    }
  }
}
</style>
